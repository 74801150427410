import React from 'react'
import { Config } from '../Config'

import LogoNebenan from '../images/icons/nebenan.png'
import LogoFacebook from '../images/icons/facebook.png'
import LogoTwitter from '../images/icons/twitter.png'
import LogoYoutube from '../images/icons/youtube.png'
import LogoMail from '../images/icons/mail.png'
import LogoInstagram from '../images/icons/instagram.png'
import LogoLinkedin from '../images/icons/linkedin.png'

export const SharingIcons = (props) => (
  <div className={`${props.className} flex-x flex-y-center flex-x-center m-flex-wrap`}>
    <a rel="noopener noreferrer" className="share-icon hover-pop-lg" href="https://www.facebook.com/nebenan.deStiftung" target="_blank"><img className="link" alt="facebook logo" src={LogoFacebook} /></a>
    <a rel="noopener noreferrer" className="share-icon hover-pop-lg" href="https://www.instagram.com/nebenan.de_stiftung/" target="_blank"><img className="link" alt="instagram logo" src={LogoInstagram} /></a>
    <a rel="noopener noreferrer" className="share-icon hover-pop-lg" href="https://twitter.com/nebenanStiftung" target="_blank"><img className="link" alt="twitter logo" src={LogoTwitter} /></a>
    <a rel="noopener noreferrer" className="share-icon hover-pop-lg" href="https://www.linkedin.com/company/nebenan-stiftung/" target="_blank"><img className="link" alt="instagram logo" src={LogoLinkedin} /></a>
    <a rel="noopener noreferrer" className="share-icon hover-pop-lg" href="https://www.youtube.com/@nebenan.de_stiftung2033" target="_blank"><img alt="whatsapp logo" className="link" src={LogoYoutube} /></a>
    {/* <a rel="noopener noreferrer" className="share-icon hover-pop-lg" href={Config.url_nebenan} target="_blank"><img className="link" alt="nebenan.de logo" src={LogoNebenan} /></a> */}
    <a rel="noopener noreferrer" className="share-icon hover-pop-lg" href={`mailto:?subject=${Config.mailto_subject}&body=${Config.mailto_content}`}><img alt="mail" className="link" src={LogoMail} /></a>
  </div>
)
